import styled from 'styled-components'
import hex2rgba from '../utils/hex2rgba'
import { BP_SMALL } from '../styles/theme'

const ShareButton = styled.a`
  color: ${props => props.theme.textLight};
  background-color: ${props => props.color};
  border-radius: 20px;
  padding: 2px 2rem;
  width: 70px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 0 0.5rem;
  &:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  &:hover {
    background-color: ${props => hex2rgba(props.color, 0.8)};
  }
`

const RichContent = styled.div`
  margin-bottom: 5rem;
  width: 100%;
  font-size: 16px;
  & > iframe {
    display: block;
    margin: 2rem auto;
    @media only screen and (max-width: ${BP_SMALL}) {
      width: 100%;
    }
  }
  img {
    max-width: 60%;
    display: block;
    margin: 2rem auto;
    @media only screen and (max-width: ${BP_SMALL}) {
      max-width: 100%;
    }
  }
`
export { ShareButton, RichContent }
