import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { Row, Col } from 'react-styled-flexboxgrid'
import Layout from '../components/Layout'
import SectionHeaderStyled from '../components/common/SectionHeader.styled'
import image from '../images/section_header_background.png'
import ShareLinks from '../components/common/ShareLinks'
import { FormattedMessage } from 'react-intl'
import { RichContent } from './news-post.styled'

class MediaTemplate extends React.Component {
  render() {
    const news = get(this.props, 'data.contentfulMedia')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { href } = this.props.location

    const bodyHtml = news.description.childMarkdownRemark.html
    return (
      <Layout
        title={news.title}
        description={bodyHtml && bodyHtml.replace(/<[^>]*>/g, '')}
        image={news.heroImage.fluid.src}
        {...this.props}
      >
        <Helmet title={`${news.title} | ${siteTitle}`} />
        <SectionHeaderStyled image={image} height={550}>
          <Row center="xs" middle="xs">
            <Col xs={11} lg={7}>
              <div>
                <h1 style={{ color: 'white' }}>{news.title}</h1>
                <Img
                  alt={news.title}
                  fluid={news.heroImage.fluid}
                  style={{ height: '300px' }}
                />
              </div>
              <p
                style={{
                  display: 'block',
                }}
              >
                {news.publishDate}
              </p>
              <RichContent
                dangerouslySetInnerHTML={{
                  __html: news.description.childMarkdownRemark.html,
                }}
              />
              <div style={{ maxWidth: '250px', paddingBottom: '2rem' }}>
                <h3 style={{ margin: 0 }}>
                  <FormattedMessage id="element.label_know_more" />
                </h3>
                <a
                  style={{
                    textTransform: 'uppercase',
                    color: '#F8BA00',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    textDecoration: 'none',
                  }}
                  href={news.url}
                >
                  <FormattedMessage id="element.label_view_note" />
                </a>
                <h3 style={{ margin: 0, marginTop: '1rem' }}>
                  <FormattedMessage id="element.label_share" />
                </h3>
                <ShareLinks key={href} href={href} />
              </div>
            </Col>
          </Row>
        </SectionHeaderStyled>
      </Layout>
    )
  }
}

export default MediaTemplate

export const pageQuery = graphql`
  query MediaBySlug($slug: String!) {
    contentfulMedia(slug: { eq: $slug }) {
      title
      # url
      publishDate(formatString: "MMM D, YYYY")
      heroImage {
        fluid(maxWidth: 1180) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
