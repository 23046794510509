import styled from 'styled-components'

import { BP_MEDIUM } from '../../styles/theme'

export default styled.div`
  min-height: 100vh;
  padding-top: 150px;
  background-image: url(${props => props.image});
  background-size: 100%
    ${props => (props.height ? `${props.height}px` : '320px')};
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${BP_MEDIUM}) {
    background-size: 140%
      ${props => (props.height ? `${props.height}px` : '320px')};
  }
`
