import React from 'react'
import { ShareButton } from '../../templates/news-post.styled'
import Icon from './Icon'

const ShareLinks = ({ href }) => (
  <div style={{ display: 'flex' }}>
    <ShareButton
      href={`https://www.facebook.com/sharer/sharer.php?u=${href}`}
      color="#3a549e"
      target="_blank"
    >
      <Icon icon={['fab', 'facebook-f']} size="1x" />
    </ShareButton>
    <ShareButton
      href={`https://twitter.com/home?status=${href}`}
      color="#1c9ff1"
      target="_blank"
    >
      <Icon icon={['fab', 'twitter']} size="1x" />
    </ShareButton>
    <ShareButton
      href={`https://wa.me/?text=${href}`}
      color="#25d267"
      target="_blank"
    >
      <Icon icon={['fab', 'whatsapp']} size="1x" />
    </ShareButton>
  </div>
)

export default ShareLinks
